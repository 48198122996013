const company = {name: 'Company', description: 'Company'}
const enterprise = {name: 'Enterprise', description: 'Enterprise'}
const ultimate = {name: 'Ultimate', description: 'Ultimate'}
const trial = {name: 'Trial', description: 'Trial'}
const custom = {name: 'Custom', description: 'Custom'}

const licenseType = [
  company,
  enterprise,
  ultimate,
  trial,
  custom
]

export {company, enterprise, ultimate, trial, custom}
export default licenseType
