import 'antd/dist/antd.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {RouterProvider} from 'react-router-dom'
import {AuthProvider} from './authContext'
import './index.css'
import {router} from './main'
import reportWebVitals from './reportWebVitals'
import RequireAuth from './RequireAuth'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RequireAuth>
        <RouterProvider router={router}/>
      </RequireAuth>
    </AuthProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
