import {Button, Form, Input, Radio, Space} from 'antd'
import {useState} from 'react'
import LicenseInfo from './LicenseInfo'
import productType, {formEngine, workflowEngine} from './productType'
import settings from './settings'

const {TextArea} = Input

const LicenseChecker = () => {
  const [componentDisabled, setComponentDisabled] = useState(false)
  const [licenseResponse, setLicenseResponse] = useState()
  const [requestError, setRequestError] = useState('')
  const [form] = Form.useForm()
  const licenseKey = Form.useWatch('licenseKey', form)
  const buttonDisabled = !licenseKey
  const pasteButtonEnabled = !!navigator.clipboard.readText

  const onFormSubmit = formValue => {
    setComponentDisabled(true)
    setRequestError(null)
    const body = {key: formValue.licenseKey}
    const url = formValue.product === formEngine.name ? `${settings.checkUrl}/formEngineLicense` : `${settings.checkUrl}/${formValue.product}`
    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {'Content-type': 'application/json'},
      body: JSON.stringify(body),
    }).then(response => response.json())
      .then(json => {
        setComponentDisabled(false)
        setLicenseResponse(json)
      })
      .catch(err => {
        setComponentDisabled(false)
        setLicenseResponse(null)
        setRequestError(err?.message || JSON.stringify(err))
      })
  }

  const onPasteAndCheck = () => {
    navigator.clipboard.readText()
      .then(text => {
        form.setFieldValue('licenseKey', text)
        form.submit()
      })
      .catch(err => console.error(err))
  }

  const products = productType.map(p => <Radio.Button key={p.name} value={p.name}>{p.description}</Radio.Button>)

  return <>
    <Form form={form} name="licenseCheck" initialValues={{product: workflowEngine.name}} onFinish={onFormSubmit}
          disabled={componentDisabled} labelCol={{span: 4}} wrapperCol={{span: 14}}>
      <Form.Item label="Product" name="product"
                 rules={[{required: true, message: 'Please choose product!'}]}>
        <Radio.Group buttonStyle="solid">
          {products}
        </Radio.Group>
      </Form.Item>
      <Form.Item label="License Key" name="licenseKey"
                 rules={[{required: true, message: 'Please input your license key!'}]}>
        <TextArea rows={10} placeholder="Enter your license key here"/>
      </Form.Item>
      <Form.Item wrapperCol={{offset: 4, span: 16}}>
        <Space>
          <Button type="primary" htmlType="submit" disabled={buttonDisabled}>Check</Button>
          {pasteButtonEnabled &&
            <Button type="default" htmlType="button" onClick={onPasteAndCheck}>
              Paste from clipboard and check
            </Button>
          }
        </Space>
      </Form.Item>
    </Form>
    {requestError && <h1 style={{color: 'red'}}>Unable to check license: {requestError}</h1>}
    {licenseResponse && <LicenseInfo licenseInfo={licenseResponse}/>}
  </>
}

export default LicenseChecker
