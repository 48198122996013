const backendUrl = '/api'

const settings = {
  loginUrl: `${backendUrl}/login`,
  logoutUrl: `${backendUrl}/logout`,
  checkAuthUrl: `${backendUrl}/checkAuth`,
  checkUrl: `${backendUrl}/check`,
  generateUrl: `${backendUrl}/generate`,
  historyUrl: `${backendUrl}/history`,
}

export default settings
