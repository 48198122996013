import {notification} from 'antd'

const showInfo = (message, description) => {
  notification.info({
    message: message,
    description: description
  })
}

const showError = (message, description) => {
  notification.error({
    message: message,
    description: description
  })
}

const copyToClipboard = data => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(data)
      .then(() => showInfo('Copied to clipboard'))
      .catch(err => showError('Copy to clipboard error', `${err}`))
  } else {
    showError('You browser doesn\'t support clipboard API')
  }
}

const saveToFile = (data, filename) => {
  const blob = new Blob([data], {type: 'text/plain;charset=utf-8'})
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename)
    return
  }

  const a = document.createElement('a')
  const url = URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  setTimeout(() => {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, 0)
}

export {showInfo, showError, copyToClipboard, saveToFile}
