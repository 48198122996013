import {CheckCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons'
import Highlight, {defaultProps} from 'prism-react-renderer'

const ValidityIcon = ({licenseInfo}) => {
  const isGood = licenseInfo?.isValid === true && licenseInfo?.hashValid === true
  if (isGood) return <CheckCircleTwoTone twoToneColor="#52c41a"/>
  return <CloseCircleTwoTone twoToneColor={'red'}/>
}

const LicenseInfo = ({licenseInfo}) => {
  const code = JSON.stringify(licenseInfo, null, 2)
  return <>
    <h2>License info <ValidityIcon licenseInfo={licenseInfo}/></h2>
    <Highlight {...defaultProps} code={code} language="json">
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <pre className={className} style={style}>
                    {tokens.map((line, i) => (
                      <div {...getLineProps({line, key: i})}>
                        {line.map((token, key) => (
                          <span {...getTokenProps({token, key})} />
                        ))}
                      </div>
                    ))}
                </pre>
      )}
    </Highlight>
  </>
}

export default LicenseInfo
