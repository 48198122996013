import {Col, Row, Spin} from 'antd'
import {useAuthContext} from './authContext'

const loginPath = '/login'

const redirectToLoginPage = () => {
  if (!document.location.pathname.startsWith(loginPath)) {
    document.location = loginPath
    return true
  }
}

const LoadingSpinner = () => {
  return <Row style={{marginTop: 'calc(50vh - 23px)'}}>
    <Col span={11}/>
    <Col span={2}>
      <Spin tip="Loading..."/>
    </Col>
    <Col span={11}/>
  </Row>
}

const RequireAuth = ({children}) => {
  const {authed} = useAuthContext()

  if (typeof authed === 'undefined') return <LoadingSpinner/>

  if (authed !== true && redirectToLoginPage()) {
    return
  }

  return <>{children}</>
}

export default RequireAuth
