import {CheckCircleTwoTone, CloseCircleTwoTone, SearchOutlined} from '@ant-design/icons'
import {Button, Input, Space, Table, Tag, Tooltip, Typography} from 'antd'
import {isFuture} from 'date-fns'
import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import licenseType, {company, enterprise, trial, ultimate} from './licenseType'
import productType from './productType'
import settings from './settings'
import {showError} from './utils'

const {Text} = Typography

const licenseColor = {
  [company.name.toUpperCase()]: 'purple',
  [enterprise.name.toUpperCase()]: 'cyan',
  [ultimate.name.toUpperCase()]: 'green',
  [trial.name.toUpperCase()]: 'geekblue',
}

const LicenseType = ({name}) => {
  if (!name) return
  const type = name.toUpperCase()
  const color = licenseColor[type] ?? 'volcano'
  return <Tag color={color} key={type}>{type}</Tag>
}

const CompanyName = ({record}) => {
  return <Tooltip title={record.bitrixUrl} placement="right">
    <a href={record.bitrixUrl} target="_blank" rel="noreferrer">{record.companyName}</a>
  </Tooltip>
}

const LicenseEndDate = ({record}) => {
  const licenseEndDate = new Date(record.licenseEndDate)
  const endsInFuture = isFuture(licenseEndDate)

  const LicenseIcon = () => endsInFuture
    ? <CheckCircleTwoTone twoToneColor="#52c41a"/>
    : <CloseCircleTwoTone twoToneColor={'red'}/>

  const tooltipText = endsInFuture ? 'License is active' : 'License is expired'
  return <Tooltip title={tooltipText} placement="left">
    <span style={{marginRight: 10}}>{record.licenseEndDate}</span>
    <LicenseIcon/>
  </Tooltip>
}

const GetColumnSearchProps = (dataIndex, searchInput, handleSearch, handleReset, setSearchedColumn, setSearchText, columnTitle) => {
  const onFilterClick = (confirm, selectedKeys) => {
    confirm({
      closeDropdown: false,
    })
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  return ({
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => <div style={{padding: 8}}>
        <Input ref={searchInput} placeholder={`Search ${columnTitle}`} value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{marginBottom: 8, display: 'block'}}/>
        <Space>
          <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined/>} size="small" style={{width: 90}}>
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
          <Button type="link" size="small" onClick={() => onFilterClick(confirm, selectedKeys)}>
            Filter
          </Button>
        </Space>
      </div>,
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
      onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100)
        }
      }
    }
  )
}

const loadHistoryData = request => new Promise((resolve, reject) => {
  fetch(settings.historyUrl,
    {
      method: 'POST',
      credentials: 'include',
      headers: {'Content-type': 'application/json'},
      body: JSON.stringify(request)
    })
    .then(response => {
      if (response.ok) return response.json().catch(reject)
      reject('Cannot get response from server')
    })
    .then(resolve)
    .catch(reject)
})

const GenerateHistory = () => {
  const [filteredInfo, setFilteredInfo] = useState({})
  const [sortedInfo, setSortedInfo] = useState({})
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const columnSortOrder = columnName => sortedInfo.columnKey === columnName ? sortedInfo.order : null

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  const getSearchProps = (dataIndex, columnTitle) => {
    return GetColumnSearchProps(dataIndex, searchInput, handleSearch, handleReset, setSearchedColumn, setSearchText, columnTitle)
  }

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      sortOrder: columnSortOrder('id'),
    },
    {
      title: 'Company name',
      dataIndex: 'companyName',
      key: 'companyName',
      sorter: true,
      sortOrder: columnSortOrder('companyName'),
      ...getSearchProps('companyName', 'Company name'),
      render: (_, record) => <CompanyName record={record}/>,
    },
    {
      title: 'Product',
      dataIndex: 'productType',
      key: 'productType',
      sorter: true,
      sortOrder: columnSortOrder('productType'),
      filters: productType.map(p => ({text: p.name, value: p.name})),
      filteredValue: filteredInfo.productType || null,
    },
    {
      title: 'License type',
      dataIndex: 'licenseType',
      key: 'licenseType',
      sorter: true,
      sortOrder: columnSortOrder('licenseType'),
      filters: licenseType.map(l => ({text: l.name, value: l.name})),
      filteredValue: filteredInfo.licenseType || null,
      render: licenseType => <LicenseType name={licenseType}/>,
    },
    {
      title: 'License end date',
      dataIndex: 'licenseEndDate',
      key: 'licenseEndDate',
      sorter: true,
      sortOrder: columnSortOrder('licenseEndDate'),
      render: (_, record) => <LicenseEndDate record={record}/>,
    },
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortOrder: columnSortOrder('createdAt'),
    },
    {
      title: 'Generated by',
      dataIndex: 'generatedBy',
      key: 'generatedBy',
      sorter: true,
      sortOrder: columnSortOrder('generatedBy'),
    },
    {
      title: 'Action',
      key: 'action',
      filteredValue: null,
      render: (_, record) => <Space size="middle">
        <Link to={`/generate/${record.id}`}>Renew</Link>
      </Space>,
    },
  ]

  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const getServerSort = () => {
    if (!tableParams.field) return
    const direction = tableParams.order === 'ascend' ? 'asc' : 'desc'
    return {column: tableParams.field, direction: direction}
  }

  const fetchData = () => {
    const request = {
      sort: getServerSort(),
      filters: tableParams.filters,
      search: [{column: searchedColumn, value: searchText}],
      pagination: {
        pageIndex: tableParams.pagination.current,
        pageSize: tableParams.pagination.pageSize
      }
    }

    setLoading(true)
    loadHistoryData(request)
      .then(data => {
        data.items?.forEach(item => item.key = item.id)
        setData(data.items)
        setLoading(false)

        setTableParams({
          ...tableParams,
          pagination: {current: data.pageIndex, pageSize: data.pageSize, total: data.count},
        })
      })
      .catch(err => {
        showError('An error has occurred', `${err}`)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [JSON.stringify(tableParams)])

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters)
    setSortedInfo(sorter)
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })
  }
  const clearAll = () => {
    setFilteredInfo({})
    setSortedInfo({})
    setSearchText('')
    setTableParams({
      pagination: tableParams.pagination
    })
  }

  const showTotal = (total, [from, to]) => <Text>{to - from + 1} of {total} [{from}-{to}]</Text>

  return <>
    <Button onClick={clearAll} style={{marginBottom: 8, width: '15%'}}>Clear filters and sorters</Button>
    <Table columns={columns} dataSource={data} loading={loading} onChange={handleTableChange}
           pagination={{...tableParams.pagination, showTotal: showTotal, showSizeChanger: true}}/>
  </>
}

export default GenerateHistory
