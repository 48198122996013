const workflowEngine = {name: 'WFE', description: 'Workflow Engine'}
const workflowServer = {name: 'WFS', description: 'Workflow Server'}
const dwKit = {name: 'DWKit', description: 'DWKit'}
const formEngine = {name: "FE", description: "Form Engine"}

const productType = [
  workflowEngine,
  workflowServer,
  dwKit,
  formEngine,
]

export {workflowEngine, workflowServer, dwKit, formEngine}
export default productType
