import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import {Button, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Space, Switch, Typography} from 'antd'
import moment from 'moment/moment'
import {useEffect, useState} from 'react'
import {useLoaderData} from 'react-router-dom'
import defaultRestrictions from './defaultRestrictions.json'
import licenseType, {company, custom, trial} from './licenseType'
import productType, {formEngine, workflowEngine} from './productType'
import settings from './settings'
import {copyToClipboard, saveToFile, showError, showInfo} from './utils'

const {TextArea} = Input
const {Text} = Typography

const truncateTime = dt => dt.zone(0).startOf('day')

const submitDefaultForm = data => new Promise((resolve, reject) => {
  const payload = {
    licenseParams: {
      WFE: {
        StrictCheck: data.strictCheck === true
      }
    },
    endDate: truncateTime(data.endDate),
    licenseHashVersion: data.licenseVersion ? 'v2' : 'v1',
    ...data
  }
  if (data.licenseType.toLowerCase() === 'custom') {
    payload.licenseParams.WFE = Object.assign(
      payload.licenseParams.WFE,
      ...data.restrictions
        .filter(item => item.type === 'WFE')
        .map(item => ({[item.name]: item.value})))

    if (data.productType.toLowerCase() !== 'wfe') {
      payload.licenseParams[data.productType] = Object.assign(
        {},
        ...data.restrictions
          .filter(item => item.type === data.productType)
          .map(item => ({[item.name]: item.value})))
    }
  }

  delete payload.startDate
  delete payload.strictCheck
  delete payload.licenseVersion
  delete payload.restrictions
  fetch(`${settings.generateUrl}`, {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(payload),
  }).then(response => {
    if (response.ok) {
      response.json().then(json => resolve?.(json)).catch(err => reject?.(err))
      return
    }
    reject?.('Cannot parse response')
  })
    .catch(err => reject?.(err))
})

const submitFormEngineForm = data => new Promise((resolve, reject) => {
  const payload = {
    licenseParams: {
      FE: {
        StrictCheck: data.strictCheck === true
      }
    },
    endDate: truncateTime(data.endDate),
    ...data
  }
  delete payload.startDate
  delete payload.strictCheck
  fetch(`${settings.generateUrl}/fe`, {
    method: 'POST',
    credentials: 'include',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(payload),
  }).then(response => {
    if (response.ok) {
      response.json().then(json => resolve?.(json)).catch(err => reject?.(err))
      return
    }
    reject?.('Cannot parse response')
  }).catch(err => reject?.(err))
})

const submitForm = data => new Promise((resolve, reject) => {
  if (data.productType === formEngine.name) {
    submitFormEngineForm(data).then(resolve).catch(reject)
  } else {
    submitDefaultForm(data).then(resolve).catch(reject)
  }
})

const GeneratedLicense = props => {
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldValue('generatedKey', props.generatedKey)
  }, [form, props.generatedKey])

  const onCopyToClipboard = () => {
    const formData = form.getFieldsValue(true)
    copyToClipboard(formData.generatedKey)
  }

  const onDownload = () => {
    const formData = form.getFieldsValue(true)
    saveToFile(formData.generatedKey, 'license.key')
  }

  const buttonDisabled = !props.generatedKey

  return <Form form={form} labelCol={{span: 4}} wrapperCol={{span: 16}}>
    <Form.Item name="generatedKey" label="Generated key">
      <TextArea rows={10} disabled={true} placeholder="Your generated key will be here"/>
    </Form.Item>
    <Form.Item wrapperCol={{offset: 4, span: 16}}>
      <Button type="primary" htmlType="submit" onClick={onCopyToClipboard} disabled={buttonDisabled}>
        Copy to clipboard
      </Button>
      <Button style={{marginLeft: 16}} htmlType="button" onClick={onDownload} disabled={buttonDisabled}>
        Download license file
      </Button>
    </Form.Item>
  </Form>
}

const LicenseGenerator = () => {
  const [componentDisabled, setComponentDisabled] = useState(false)
  const [form] = Form.useForm()
  const [generatedKey, setGeneratedKey] = useState('')
  const [customLicense, setCustomLicense] = useState(false)
  const [customRestrictionTypes, setCustomRestrictionTypes] = useState(['WFE'])
  const keyGenData = useLoaderData()
  const [isFormEngineProduct, setFormEngineProduct] = useState(false)
  let keyGenParams
  try {
    keyGenParams = JSON.parse(keyGenData?.keyGenParams)
  } catch (e) {
    // do nothing
  }

  const onFinish = values => {
    setComponentDisabled(true)
    submitForm(values)
      .then(json => {
        setComponentDisabled(false)
        showInfo('License created successfully')
        setGeneratedKey(json.key)
      })
      .catch(err => {
        setComponentDisabled(false)
        showError('An error has occurred', `${err}`)
      })
  }

  const onStartDateChange = value => {
    const formData = form.getFieldsValue(true)
    if (!value || !formData.duration) return
    form.setFieldValue('endDate', value.clone().add(1, formData.duration))
  }

  const changeDates = value => {
    const formData = form.getFieldsValue(true)
    let startDate = formData.startDate
    if (!startDate) {
      startDate = moment()
      form.setFieldValue('startDate', startDate)
    }
    form.setFieldValue('endDate', startDate.clone().add(1, value))
  }

  const onPeriodChange = e => changeDates(e.target.value)

  const updateDuration = newDuration => {
    form.setFieldValue('duration', newDuration)
    changeDates(newDuration)
  }

  const updateStrictCheck = value => {
    form.setFieldValue('strictCheck', value)
  }

  const updateLicenseHashVersion = value => {
    form.setFieldValue('licenseVersion', value)
  }

  const onTypeChange = e => {
    if (e.target.value.toLowerCase() === 'custom') {
      setCustomLicense(true)
      updateLicenseHashVersion(true)
    } else {
      setCustomLicense(false)
    }

    if (e.target.value.toLowerCase() === 'trial') {
      form.setFieldValue('companyName', e.target.value.toUpperCase())
      updateDuration('months')
      updateStrictCheck(true)
    } else {
      updateDuration('years')
      updateStrictCheck(false)
    }
  }

  const onProductChange = e => {
    if (e.target.value === formEngine.name) {
      setFormEngineProduct(true)
      setCustomRestrictionTypes([])
      form.setFieldValue('licenseType', company.name)
      updateStrictCheck(false)
    } else {
      setFormEngineProduct(false)
      const customRestrictionTypes = ['WFE']
      if (e.target.value.toLowerCase() !== 'wfe') {
        customRestrictionTypes.push(e.target.value)
      }
      setCustomRestrictionTypes(customRestrictionTypes)
    }
  }

  const getEditor = (editor, values) => {
    switch (editor) {
      case 'boolean':
        return <Checkbox/>
      case 'number':
        return <InputNumber/>
      case 'select':
        return <Select style={{width:150}} options={values.map(item => (
          {
            value: item,
            label: item
          }
        ))}/>
      default:
        return <Input placeholder="Value"/>
    }
  }

  const getValuePropName = (editor) => {
    if (editor === 'boolean') {
      return 'checked'
    }
  }

  const licenseButtons = licenseType.map(l => <Radio.Button key={l.name} value={l.name}>{l.description}</Radio.Button>)
  const products = productType.map(p => <Radio.Button key={p.name} value={p.name}>{p.description}</Radio.Button>)
  const initialValues = {
    productType: keyGenData?.productType || workflowEngine.name,
    companyName: keyGenData?.companyName,
    bitrixUrl: keyGenData?.bitrixUrl,
    licenseType: keyGenData?.licenseType || company.name,
    duration: 'years',
    startDate: moment(),
    endDate: keyGenData?.licenseEndDate ? moment(keyGenData?.licenseEndDate) : moment().add(1, 'years'),
    strictCheck: keyGenParams?.licenseParams?.WFE?.StrictCheck,
    restrictions: defaultRestrictions.restrictions
  }

  const isRestrictionReadonly = (index) => {
    return !!initialValues.restrictions?.[index]
  }

  const Forms = {
    CompanyName: () => (
      <Form.Item label="Company name" name="companyName"
                 rules={[{required: true, message: 'Please input company name!'}]}>
        <Input placeholder="Enter company name here"/>
      </Form.Item>
    ),
    BitrixUrl: () => (
      <Form.Item label="Bitrix URL" name="bitrixUrl"
                 rules={[{required: true, message: 'Please input company Bitrix URL!'}]}>
        <Input placeholder="Enter company Bitrix URL here"/>
      </Form.Item>
    ),
    LicensePeriod: () => (
      <>
        <Form.Item label="License duration" name="duration"
                   rules={[{required: true, message: 'Choose license duration!'}]}>
          <Radio.Group onChange={onPeriodChange}>
            <Radio value="years">Year</Radio>
            <Radio value="months">Month</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="License start date" name="startDate"
                   rules={[{required: true, message: 'Please enter valid license start date!'}]}>
          <Space>
            <DatePicker onChange={onStartDateChange} defaultValue={initialValues.startDate}/>
            <Text underline>In reality, only the "License end date" field is used.</Text>
          </Space>
        </Form.Item>
        <Form.Item label="License end date" name="endDate"
                   rules={[{required: true, message: 'Please enter valid license end date!'}]}>
          <DatePicker/>
        </Form.Item>
      </>
    ),
    ButtonGenerate: () => (
      <Form.Item wrapperCol={{offset: 4, span: 16}}>
        <Button type="primary" htmlType="submit">Generate</Button>
      </Form.Item>
    ),
    StrictCheck: () => (
      <Form.Item label="Strict license verification" name="strictCheck" valuePropName="checked">
        <Switch checkedChildren="Enabled" unCheckedChildren="Disabled"/>
      </Form.Item>
    )
  }

  const defaultForm = () => (
    <>
      <Forms.CompanyName/>
      <Forms.BitrixUrl/>
      <Form.Item label="License type" name="licenseType"
                 rules={[{required: true, message: 'Choose license type!'}]}>
        <Radio.Group buttonStyle="solid" onChange={onTypeChange}>
          {licenseButtons}
        </Radio.Group>
      </Form.Item>
      <Forms.LicensePeriod/>
      <Forms.StrictCheck/>
      <Form.Item label="License hash version" name="licenseVersion" valuePropName="checked">
        <Switch checkedChildren="V2" unCheckedChildren="V1" disabled={customLicense}/>
      </Form.Item>
      {customLicense && (
        <Form.Item label="Restrictions">
          <Form.List name="restrictions">
            {(fields, {add, remove}) => (
              <>
                {fields.map(({key, name, ...restField}) => (
                  <Space key={key} style={{display: 'flex'}} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'type']}
                      rules={[{required: true, message: 'Missing type restriction'}]}
                      initialValue={initialValues.restrictions[key] ? undefined : customRestrictionTypes[0]}>
                      <Select
                        disabled={isRestrictionReadonly(name)}
                        options={customRestrictionTypes.map(item => {
                          return {
                            value: item,
                            label: item
                          }
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      rules={[{required: true, message: 'Missing name'}]}
                    >
                      <Input placeholder="Restriction" disabled={isRestrictionReadonly(name)}/>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      rules={[{required: true, message: 'Missing value'}]}
                      valuePropName={getValuePropName(initialValues.restrictions[name]?.editor)}
                    >
                      {
                        getEditor(initialValues.restrictions[name]?.editor, initialValues.restrictions[name]?.values)
                      }

                    </Form.Item>
                    {isRestrictionReadonly(name) || <MinusCircleOutlined onClick={() => remove(name)}/>}
                  </Space>
                ))}
                {<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                  Add field
                </Button>}
              </>
            )}
          </Form.List>
        </Form.Item>
      )}
      <Forms.ButtonGenerate/>
    </>
  )

  const formEngineLicenseButtons = [company, trial]
    .map(l => <Radio.Button key={l.name} value={l.name}>{l.description}</Radio.Button>)
  const formEngineForm = () => (
    <>
      <Forms.CompanyName/>
      <Forms.BitrixUrl/>
      <Form.Item label="License type" name="licenseType"
                 rules={[{required: true, message: 'Choose license type!'}]}>
        <Radio.Group buttonStyle="solid" onChange={onTypeChange}>
          {formEngineLicenseButtons}
        </Radio.Group>
      </Form.Item>
      <Forms.LicensePeriod/>
      <Forms.StrictCheck/>
      <Forms.ButtonGenerate/>
    </>
  )

  return <>
    <Form form={form} disabled={componentDisabled} onFinish={onFinish}
          labelCol={{span: 4}} wrapperCol={{span: 14}} initialValues={initialValues}>
      <Form.Item label="Product" name="productType"
                 rules={[{required: true, message: 'Please choose product!'}]}>
        <Radio.Group buttonStyle="solid" onChange={onProductChange}>
          {products}
        </Radio.Group>
      </Form.Item>
      {isFormEngineProduct ? formEngineForm() : defaultForm()}
    </Form>
    <GeneratedLicense generatedKey={generatedKey}/>
  </>
}

export default LicenseGenerator
