import React from 'react'
import {createBrowserRouter,} from 'react-router-dom'
import ErrorPage from './error-page'
import GenerateHistory from './GenerateHistory'
import LicenseChecker from './LicenseChecker'
import LicenseGenerator from './LicenseGenerator'
import LoginPage from './LoginPage'
import Root, {historyEntityLoader} from './routes/root'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: 'generate',
        element: <LicenseGenerator/>,
      },
      {
        path: 'generate/:generateId',
        element: <LicenseGenerator/>,
        loader: historyEntityLoader,
      },
      {
        path: 'check',
        element: <LicenseChecker/>,
      },
      {
        path: 'generate-history',
        element: <GenerateHistory/>,
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage/>,
    errorElement: <ErrorPage/>,
  },
])

export {router}
