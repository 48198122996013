import {Button, Layout, Menu, PageHeader} from 'antd'
import {useEffect} from 'react'
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom'
import {useAuthContext} from '../authContext'
import settings from '../settings'

const LogoutButton = () => {
  const {logout} = useAuthContext()
  const onLogout = () => logout()
    .then(() => {
    })
    .catch(err => console.error(err))
  return <Button onClick={onLogout}>Logout</Button>
}

const Username = () => {
  const {userData} = useAuthContext()
  if (!userData) return
  return `${userData?.firstName} ${userData.lastName}` ?? userData?.username ?? 'Unknown'
}

const historyEntityLoader = async ({ params }) => {
  const response = await fetch(`${settings.historyUrl}/${params.generateId}`,
    {
      method: 'GET',
      credentials: 'include',
    })
  if (response.status === 200) {
    return await response.json()
  }
}

const Root = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const currentSelectedKey = location.pathname?.replace('/', '')
  useEffect(() => {
    if (!currentSelectedKey) navigate('generate')
  }, [currentSelectedKey])

  return <Layout>
    <PageHeader title={'OptimaJet key generator'} extra={[<Username key="1"/>, <LogoutButton key="2"/>,]}>
      <Menu
        mode="horizontal"
        selectedKeys={[currentSelectedKey]}
        items={[{
          key: 'generate', label: <Link to={`generate`}>Generate</Link>,
        }, {
          key: 'check', label: <Link to={`check`}>Check</Link>,
        }, {
          key: 'generate-history', label: <Link to={`generate-history`}>History</Link>,
        },]}
      />
    </PageHeader>
    <Layout className="main-content">
      <Outlet/>
    </Layout>
  </Layout>
}

export default Root
export {historyEntityLoader}
