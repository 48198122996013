import {Button, Checkbox, Col, Form, Image, Input, Row} from 'antd'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuthContext} from './authContext'

const LoginForm = () => {
  const [errorMessage, setErrorMessage] = useState([])
  const [disabled, setDisabled] = useState(false)
  const {login} = useAuthContext()
  const navigate = useNavigate()

  const onFinish = values => {
    setDisabled(true)
    setErrorMessage([])

    login(values)
      .then(() => {
        setDisabled(false)
        navigate('/generate')
      })
      .catch(err => {
        setDisabled(false)
        setErrorMessage([<span style={{color: 'red'}}>{err || 'A login error has occurred.'}</span>])
      })
  }

  return <Form name="basic" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={{remember: true}}
               onFinish={onFinish} autoComplete="off" disabled={disabled}>
    <Form.Item>
      <Image style={{marginLeft: 152}} width={200} preview={false} src="logo192.png"/>
    </Form.Item>
    <Form.Item label="Username" name="username"
               rules={[
                 {
                   required: true,
                   message: 'Please input your username!',
                 },
               ]}>
      <Input/>
    </Form.Item>
    <Form.Item label="Password" name="password"
               rules={[
                 {
                   required: true,
                   message: 'Please input your password!',
                 },
               ]}>
      <Input.Password/>
    </Form.Item>
    <Form.Item name="remember" valuePropName="checked" wrapperCol={{offset: 8, span: 16}}>
      <Checkbox>Remember me</Checkbox>
    </Form.Item>
    <Form.Item wrapperCol={{offset: 8, span: 16}}>
      <Button type="primary" htmlType="submit">Login</Button>
      <Form.ErrorList errors={errorMessage}/>
    </Form.Item>
  </Form>
}

const LoginPage = () => {
  return <Row style={{marginTop: 'calc(50vh - 124px)'}}>
    <Col span={8}/>
    <Col span={8}>
      <LoginForm/>
    </Col>
    <Col span={8}/>
  </Row>
}

export default LoginPage
