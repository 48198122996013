import {createContext, useContext, useEffect, useState} from 'react'
import settings from './settings'

const getCookie = cookieName => document.cookie
  .split('; ')
  .find(row => row.startsWith(cookieName + '='))
  ?.split('=')[1]

const getUserDataFromCookie = () => {
  const cookieValue = getCookie('userData')
  if (!cookieValue) return
  return JSON.parse(decodeURIComponent(cookieValue))
}

const fetchCheckAuth = () => new Promise((resolve, reject) => {
  fetch(settings.checkAuthUrl, {method: 'POST', credentials: 'include'})
    .then(response => {
      if (response.ok) {
        resolve()
        return
      }
      reject('Wrong response code: ' + response.statusCode)
    })
    .catch(err => reject('' + err))
})

const useAuth = () => {
  const [authed, setAuthed] = useState(undefined)
  const [userData, setUserData] = useState()

  const setLoggedIn = () => {
    setAuthed(true)
    setUserData(getUserDataFromCookie())
  }

  const setLoggedOut = () => {
    setAuthed(false)
    setUserData(undefined)
  }

  useEffect(() => {
    fetchCheckAuth()
      .then(setLoggedIn)
      .catch(setLoggedOut)
  }, [])

  return {
    authed,
    userData,
    login: credentials => new Promise((resolve, reject) => {
      fetch(settings.loginUrl,
        {
          method: 'POST',
          credentials: 'include',
          headers: {'Content-type': 'application/json'},
          body: JSON.stringify(credentials),
        })
        .then(response => {
          if (response.ok) {
            setLoggedIn()
            resolve()
            return
          }
          setLoggedOut()
          reject('Wrong username or password!')
        })
        .catch(err => {
          setLoggedOut()
          reject('' + err)
        })
    }),
    logout: () => new Promise((resolve, reject) => {
      fetch(settings.logoutUrl, {method: 'POST', credentials: 'include'})
        .then(response => {
          if (response.ok) {
            setLoggedOut()
            resolve()
            return
          }
          reject('Wrong response code: ' + response.statusCode)
        })
        .catch(err => reject('' + err))
    }),
  }
}

const AuthContext = createContext(null)

const AuthProvider = ({children}) => {
  const auth = useAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

const useAuthContext = () => useContext(AuthContext)

export {AuthProvider, useAuthContext}
